import React from 'react'

import Layout from '../components/organisms/Layout/Layout'
import PageWidth from "../components/organisms/PageWidth/PageWidth";
import SEO from '../components/atoms/SEO/SEO'

const Coaching = () => (
    <Layout>
        <SEO title="Privacy Policy" description="I will treat your data confidential and try to protect it from unauthorized third parties." />
        <PageWidth isContent={true}>
            <h1>Privacy Policy</h1>
            <p>I will treat your data confidential and try to protect it from unauthorized third parties. I will only pass on your data, if it is necesarry to our collaboration. Your data will only be used for the purpose you requested. You may access, change or delete your data.</p>
            <h2>E-Mail Contact</h2>
            <p>If you contact me via e-mail, your e-mail, including the personal data contained within it, is saved on my mail server. Older mails are deleted on a regular basis.</p>
        </PageWidth>
    </Layout>
)

export default Coaching
